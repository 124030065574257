import React, {useState} from "react";
import Button from "react-bootstrap/esm/Button"
import Heena from "../../assets/images/heena.jpg"
import happyClinets from "../../assets/images/happyClinets.png"
import ConsultantModel from '../pricingComponents/consultantModel'

export default function HeroSection(){
    const [showModal, setShowModal] = useState(false);
    const [step, setStep] = useState(1);

    const handleClick = () => {
        setShowModal(true);
        setStep(1); // Reset step to 1 when opening the modal
    };

    const handleHide = () => {
        setShowModal(false);
        setStep(1); // Reset step to 1 when closing the modal
    };
    return (
        <section>
            <div className='container'>
                <div className="row d-flex heroWrapper">
                    <div className="col-sm-12 col-md-6 heroContent">
                        <div className="py-5">
                            <h4 className="d-inline boldText pb-1" style={{ borderBottom: '3px solid #CBEA7B' }}>Transform Your Health with</h4>
                            <h1 className="mt-4">Expert Nutritionist<br/><span className="greenTxt">Heena Parekh</span></h1>
                            <p>Welcome to the Diet & Health Center, where Nutritionist Heena Parekh is dedicated to helping you achieve optimal health through personalized nutrition coaching. Heena provides customized nutrition plans and ongoing support to guide you on your journey to better health. Start your transformation today and experience the benefits of tailored nutrition guidance with Heena Parekh.</p>
                            <Button className="squBtn mb-3" onClick={handleClick}>Book an Consultation</Button>
                            <ConsultantModel
                                show={showModal}
                                onHide={handleHide}
                                step={step}
                                setStep={setStep}
                            />
                            <div className="d-flex align-items-center">
                                <img src={happyClinets} alt="happy customers" />
                                <p className="fw-bold ms-3"><span style={{ color: '#468671' }}>12k+</span> Happy Customers</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 heroPic d-flex justify-content-center align-items-center">
                        <img src={Heena} alt="Heena Parekh" style={{ height: window.innerWidth < 768 ? 'auto' : '550px' }} />
                    </div>
                </div>
            </div>
        </section>
    )
}