import React from 'react'
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import rightIcon from "../../assets/images/selectRight.svg"

export const PlanFeatues = () => {
    return (
        <div className='d-flex justify-content-center my-5 flex-wrap'>
            <Card className='mx-3 px-5 py-3' style={{ borderTop:"8px solid #77BD3E", boxShadow: "0px 4px 4px 0px #00000040"}}>
                <div className='d-inline text-center'>
                    <h3 className='d-inline px-4 pb-2' style={{ color: '#77BD3E', borderBottom:'2px solid #000' }}>Features</h3>
                </div>
                <Card.Body>
                    <ListGroup>
                        <ListGroup.Item style={{ border:'none' }}>Personalized Nutrition Plan</ListGroup.Item>
                        <ListGroup.Item style={{ border:'none' }}>Email Support</ListGroup.Item>
                        <ListGroup.Item style={{ border:'none' }}>One -on One  Consultations</ListGroup.Item>
                        <ListGroup.Item style={{ border:'none' }}>Body Composition Analysis</ListGroup.Item>
                        <ListGroup.Item style={{ border:'none' }}>Priority Support</ListGroup.Item>
                        <ListGroup.Item style={{ border:'none' }}>Educational Resources and Guides</ListGroup.Item>
                        <ListGroup.Item style={{ border:'none' }}>Customized Meal Plans and Recipe Suggestions </ListGroup.Item>
                        <ListGroup.Item style={{ border:'none' }}>Phone Support</ListGroup.Item>
                        <ListGroup.Item style={{ border:'none' }}>Lifestyle and Habit Assessment</ListGroup.Item>
                    </ListGroup>
                </Card.Body>
            </Card>
            <Card className='mx-3 px-5 py-3 my-2' style={{ backgroundColor:'#F0F9D9', borderTop:"8px solid transparent", boxShadow:'0px 4px 24px 0px #00000040', width: window.innerWidth < 768 ? '100%' : '19rem', display: window.innerWidth < 768 ? 'none' : 'block'}}>
                <div className='d-flex text-center justify-content-center'>
                     <h3 className='d-inline px-4 pb-2'style={{ color: '#77BD3E', borderBottom:'2px solid #000' }}>Included</h3>
                </div>
                <Card.Body>
                    <ListGroup>
                        <ListGroup.Item className='d-flex justify-content-center align-items-center' style={{ border:'none', backgroundColor:'#F0F9D9', height:'41px' }}><img src={rightIcon} alt='inclued right' /></ListGroup.Item>
                        <ListGroup.Item className='d-flex justify-content-center align-items-center' style={{ border:'none', backgroundColor:'#F0F9D9', height:'41px' }}><img src={rightIcon} alt='inclued right' /></ListGroup.Item>
                        <ListGroup.Item className='d-flex justify-content-center align-items-center' style={{ border:'none', backgroundColor:'#F0F9D9', height:'41px' }}><img src={rightIcon} alt='inclued right' /></ListGroup.Item>
                        <ListGroup.Item className='d-flex justify-content-center align-items-center' style={{ border:'none', backgroundColor:'#F0F9D9', height:'41px' }}><img src={rightIcon} alt='inclued right' /></ListGroup.Item>
                        <ListGroup.Item className='d-flex justify-content-center align-items-center' style={{ border:'none', backgroundColor:'#F0F9D9', height:'41px' }}><img src={rightIcon} alt='inclued right' /></ListGroup.Item>
                        <ListGroup.Item className='d-flex justify-content-center align-items-center' style={{ border:'none', backgroundColor:'#F0F9D9', height:'41px' }}><img src={rightIcon} alt='inclued right' /></ListGroup.Item>
                        <ListGroup.Item className='d-flex justify-content-center align-items-center' style={{ border:'none', backgroundColor:'#F0F9D9', height:'41px' }}><img src={rightIcon} alt='inclued right' /></ListGroup.Item>
                        <ListGroup.Item className='d-flex justify-content-center align-items-center' style={{ border:'none', backgroundColor:'#F0F9D9', height:'41px' }}><img src={rightIcon} alt='inclued right' /></ListGroup.Item>
                        <ListGroup.Item className='d-flex justify-content-center align-items-center' style={{ border:'none', backgroundColor:'#F0F9D9', height:'41px' }}><img src={rightIcon} alt='inclued right' /></ListGroup.Item>
                    </ListGroup>
                </Card.Body>
            </Card>
        </div>
    )
}
