import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import logo from "../assets/images/logo.png";
import ConsultantModel from './pricingComponents/consultantModel';
import Button from 'react-bootstrap/Button';

export default function AppHeader() {
    const [showModal, setShowModal] = useState(false);
    const [step, setStep] = useState(1);
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const location = useLocation();

    // Get the active link based on the current path
    const getActiveLink = () => {
        const path = location.pathname.replace('/', '') || 'home'; // Default to 'home' if root path
        return path;
    };

    const activeLink = getActiveLink();

    const handleClick = () => {
        setShowModal(true);
        setStep(1);
    };

    const handleHide = () => {
        setShowModal(false);
        setStep(1);
    };

    const toggleOffcanvas = () => setShowOffcanvas(!showOffcanvas);

    return (
        <>
            <Navbar expand="lg" className="bg-body-tertiary" id='header'>
                <Container>
                    <Navbar.Brand href="/">
                        <img src={logo} alt='logo' />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="offcanvas-navbar" onClick={toggleOffcanvas} />
                    <Navbar.Offcanvas
                        id="offcanvas-navbar"
                        aria-labelledby="offcanvas-navbar-label"
                        placement="start"
                        show={showOffcanvas}
                        onHide={() => setShowOffcanvas(false)}
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id="offcanvas-navbar-label">Menu</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-end flex-grow-1 pe-3 align-items-center">
                            {['home', 'about', 'services', 'pricing', 'testimonials', 'blogs', 'contact'].map((link) => (
                                <Nav.Link 
                                    key={link} 
                                    as={Link} // Use Link component for navigation
                                    to={`/${link === 'home' ? '' : link}`} // Adjust link for home
                                    className={activeLink === link ? 'active' : ''} 
                                    onClick={() => setShowOffcanvas(false)}
                                >
                                    {link.charAt(0).toUpperCase() + link.slice(1)}
                                </Nav.Link>
                            ))}
                                <Button className='squBtn ms-3' onClick={handleClick}>
                                    Book a Consultation
                                </Button>
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>

            {/* Modal for booking consultation */}
            <ConsultantModel
                show={showModal}
                onHide={handleHide}
                step={step}
                setStep={setStep}
            />
        </>
    );
}
