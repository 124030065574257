import React, { useEffect } from "react";
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import heenaIntro from "../../assets/images/1730145425413537.mp4"
import icon1 from "../../assets/images/icon1.png";
import icon2 from "../../assets/images/icon2.png";
import icon3 from "../../assets/images/icon3.png";


export const AboutHeena = () => {
    useEffect(() => {
        const videoElements = document.querySelectorAll("video");
    
        const handleIntersection = (entries) => {
          entries.forEach((entry) => {
            const video = entry.target;
    
            if (entry.isIntersecting) {
              video.play().catch((error) => {
                // Handle play errors silently if needed
                console.log("Play error:", error.message);
              });
            } else {
              video.pause();
            }
          });
        };
    
        const observer = new IntersectionObserver(handleIntersection, {
          threshold: 0.5, // Adjust visibility threshold
        });
    
        // Observe all video elements
        videoElements.forEach((video) => observer.observe(video));
    
        // Cleanup observer on unmount
        return () => {
          videoElements.forEach((video) => observer.unobserve(video));
          observer.disconnect();
        };
      }, []);
    return (
        <React.Fragment>
            <Row>
                <Col xs={12} sm={12} md={6}>
                    <div className='videoWrapper' style={{ background:'none' }}>
                        <video width="100%" height="250" controls>
                            <source src={heenaIntro} type="video/mp4" />
                        </video>
                    </div>
                    <h3 className='text-center'>No Tricks, No Starvation, <span className='greenTxt'>Just Balanced Nutrition</span></h3>
                    <div className='d-flex justify-content-center align-items-center'>
                        <img src={icon1} alt='' className='mx-5 my-3' style={{ height:'50px' }} />
                        <img src={icon2} alt='' className='mx-5 my-3' style={{ height:'50px' }} />
                        <img src={icon3} alt='' className='mx-5 my-3' style={{ height:'50px' }} />
                    </div>
                </Col>
                <Col xs={12} sm={12} md={6} className="px-4">
                    <p>Heena Parekh is an experienced Dietician and Nutritionist with a strong academic background and extensive professional experience. She holds a Master's degree (M.Sc) in Nutrients and Nutrition and a Bachelor's degree in Public Health Nutrition. With over 9 years of experience working in various settings such as gyms, hospitals, and pediatric hospitals, Heena has developed a comprehensive understanding of nutritional needs across different populations.</p>
                    <p>Her primary goal is to enhance the lifestyle and food habits of her clients to prevent diseases and improve their overall nutrition levels. Heena is dedicated to providing personalized diet plans tailored to each individual’s health requirements and goals. Her expertise lies in creating effective, sustainable nutrition strategies that promote long-term well-being.</p>
                    <p>For those unable to visit the clinic in person, Heena offers flexible consultation options, including online, text, and video call consultations. This ensures that you can receive professional nutritional guidance and support from the comfort of your home.</p>
                    <p>Embark on a new journey towards better health with the perfect diet plans at Diet and Health Center, where Heena Parekh is committed to helping you achieve your health and wellness goals.</p>
                </Col>
            </Row>
        </React.Fragment>
    )
}
