import React from 'react';
import { useSpring, animated } from 'react-spring';

const numberData = [
    {
        id: 1,
        no: 98,
        symbol: '%',
        desc: '% Successful Clients',
    },
    {
        id: 2,
        no: 12000,
        symbol: '',
        desc: '+ Clients consulted in total',
    },
    {
        id: 3,
        no: 99,
        symbol: '%',
        desc: '% Customer Satisfaction',
    },
    {
        id: 4,
        no: 12,
        symbol: '+',
        desc: '+ Years of Experience',
    },
];

function Number({ n }) {
    const { number } = useSpring({
        from: { number: 0 },
        number: n,
        delay: 200,
        config: { mass: 1, tension: 20, friction: 10 },
    });
    return <animated.div className='d-inline'>{number.to((n) => n.toFixed(0))}</animated.div>;
}

export const AnimatedNumbers = () => {
    return (
        <section className='my-5'>
            <div className="animatedNumbersContainer d-flex justify-content-around align-items-center flex-wrap">
                {numberData.map((number) => (
                    <div className="squreBox" key={number.id}>
                        <div className="roundBox d-flex justify-content-center align-items-center flex-column">
                            <h1>
                                <Number n={number.no} /><span>{number.symbol}</span>
                            </h1>
                            <p>{number.desc}</p>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};
