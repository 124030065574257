import React from 'react'
import Intro from './components/intro'
import ServicesSection from './components/homeComponents/services'
import ClientSays from './components/homeComponents/testimonials'
import {Faq} from './components/homeComponents/faq'
import DiscoverSeaction from './components/homeComponents/discover'
import client3 from "../src/assets/images/clientsays/client3.mp4"
import introbg from "../src/assets/images/AbstractDesign.png"

export const Services = () => (
  <React.Fragment>
        <Intro 
            bgImage={introbg}
            title="Our Nutrition Services"
            paragraph="Customised plans for your Health Goals"
        />
        <ServicesSection showTitle={false}/>
        <DiscoverSeaction />
        <ClientSays videoSource={client3} />
        <Faq />
    </React.Fragment>
)