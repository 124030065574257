import React from 'react';
import Intro from './components/intro';
import BlogGrid from './components/BlogsComponents/blogGrid';
import { Container } from 'react-bootstrap';
import introbg from "../src/assets/images/AbstractDesign.png"

export const Blogs = () => (
  <React.Fragment>
    <Intro 
        bgImage={introbg}
        title="Blogs"
        paragraph="Insights and Tips for a Healthier You."
    />    
    <Container>
      <BlogGrid />
    </Container>
  </React.Fragment>
)
