import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ConsultantModel from './consultantModel';

const plansInfo = [
    {
        id: 1,
        plan_name: 'Starter Program',
        sub_title: 'One Time Consultation',
        highlightTxt: 'One Time Consultation',
        desc1: 'Start your journey to better health with our ',
        desc2:', where you will receive a personalized nutrition assessment tailored to your specific goals and lifestyle. In just one session, we’ll provide actionable insights, expert advice, and a customized plan to help you make immediate improvements to your diet and wellness.',
        amount: 'Rs.500',
        isRecommended: false,
        btn_tzt: 'Get Started'
    },
    {
        id: 2,
        plan_name: 'Quickstart Program',
        sub_title: 'Jumpstart Your Nutrition Journey',
        desc1: 'Begin your path to better health with our QuickStart Program, a personalized ',
        highlightTxt: '1-month nutrition plan',
        desc2:' designed to deliver quick, noticeable results. This program provides a solid foundation for making sustainable dietary changes and kick-starting your wellness journey.',
        amount: 'Rs.2000',
        isRecommended: false,
        btn_tzt: 'Get Started'
    },
    {
        id: 3,
        plan_name: 'Transformative Program',
        sub_title: 'Achieve Lasting Results',
        desc1: 'Commit to our comprehensive ',
        desc2:', focusing on achieving significant, lasting results. With ongoing support and adjustments to your nutrition plan, you will build healthy habits and see meaningful progress over time.',
        highlightTxt:'3-month Transformation Program',
        amount: 'Rs.6000',
        isRecommended: true,
        btn_tzt: 'Get Started'
    },
    {
        id: 4,
        plan_name: 'Wellness Journey Program',
        sub_title: 'Transform your Health Long Term',
        desc1: 'Embark on a complete transformation with our ',
        desc2:', crafted for long-term success. Receive extended guidance and support to maintain and enhance your health, embedding lasting nutritional changes into your lifestyle.',
        highlightTxt: '6-month Wellness Journey Program',
        isRecommended: false,
        btn_tzt: 'Get Started',
        amount: 'Rs.10,000',
    }
];

export const Plans = () => {
    const [showModal, setShowModal] = useState(false);
    const [step, setStep] = useState(1);

    const handleClick = () => {
        setShowModal(true);
        setStep(1); // Reset step to 1 when opening the modal
    };

    const handleHide = () => {
        setShowModal(false);
        setStep(1); // Reset step to 1 when closing the modal
    };

    return (
        <Container>
            <div className='d-flex justify-content-around flex-wrap mt-5'>
                {
                    plansInfo.map(plan => (
                        <div key={plan.id} className="planWrapper mt-5 mb-3">
                            {plan.isRecommended && (
                                <div className="recommendedText">Recommended</div>
                            )}
                            <Card 
                                className={`d-flex justify-content-center align-items-center text-center planCard ${plan.isRecommended ? 'recommendedCard' : ''}`} 
                                style={{ width: window.innerWidth < 768 ? '100%' : '19rem' }}
                            >
                                <Card.Body>
                                    <div className='titleWrapper mb-2'>
                                        <h2 className='cardTitle'>{plan.plan_name}</h2>
                                        <h3 className="mb-2 text-muted cardSubTitle">{plan.sub_title}</h3>
                                    </div>
                                    <p style={{ minHeight: '240px' }}>
                                        {plan.desc1}<span className='greenTxt fw-semibold'>{plan.highlightTxt}</span>{plan.desc2}
                                    </p>
                                    <h2>{plan.amount}</h2>
                                    <Button
                                        variant="outline-success"
                                        onClick={handleClick}
                                        className='getButton'
                                    >
                                        {plan.btn_tzt}
                                    </Button>
                                    <ConsultantModel
                                        show={showModal}
                                        onHide={handleHide}
                                        step={step}
                                        setStep={setStep}
                                    />
                                </Card.Body>
                            </Card>
                        </div>
                    ))
                }
            </div>
        </Container>
    );
};
