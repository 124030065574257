import React from 'react'
import Intro from './components/intro'
import ClientSays from './components/homeComponents/testimonials'
import {Faq} from './components/homeComponents/faq'
import client4 from "../src/assets/images/clientsays/client4.mp4"
import introbg from "../src/assets/images/AbstractDesign.png"

export const Testimonials = () => (
  <React.Fragment>
        <Intro 
            bgImage={introbg}
            title="Testimonials"
            paragraph="See How Our Personalized Nutrition Plans Have Transformed Lives. Real Stories, Real Results"
        />
        {/* <div className='d-flex justify-content-center align-items-center'>
            <div className='beforeAfter'></div>
        </div> */}
        <div className='d-flex justify-content-around flex-wrap'>
            {/* <div className='beforeAfter1 baImg'></div>
            <div className='beforeAfter2 baImg'></div>
            <div className='beforeAfter3 baImg'></div> */}
            <img src='/assets/images/ba2.png' className='baImg' alt='before after image' />
            <img src='/assets/images/ba1.jpeg' className='baImg' alt='before after image' />
            <img src='/assets/images/ba3.png' className='baImg' alt='before after image' />
        </div>
        <ClientSays videoSource={client4} />
        <div className='my-5 py-5'>
            <h2 className='text-center'>What Our Client Eats!</h2>
            <div className='d-flex justify-content-around flex-wrap m-5 p-sm-3 p-lg-5'>
                {/* <div className='foodAnimation1 foodImg'></div>
                <div className='foodAnimation2 foodImg'></div>
                <div className='foodAnimation3 foodImg'></div>  
                <div className='foodAnimation4 foodImg'></div>   */}
                <img src='/assets/images/FF1.png' className='foodImg' alt='Client Receipes' />
                <img src='/assets/images/FF2.png' className='foodImg' alt='Client Receipes' />
                <img src='/assets/images/FF3.png' className='foodImg' alt='Client Receipes' />
                <img src='/assets/images/FF4.png' className='foodImg' alt='Client Receipes' />
            </div>
        </div>
        <Faq />
    </React.Fragment>
)
