import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';

// Services data with vector and title
var servicesData = [
    {
        id: 1,
        vector: '../assets/images/leaf.svg',
        title: 'Weight Loss',
        description: 'At Diet & Health Center, we are dedicated to offering you the best healthy diet plans tailored to help you not only reduce weight but also maintain it for a lifetime. Our approach is grounded in the understanding that achieving and sustaining a healthy weight requires more than just short-term dietary changes; it necessitates a holistic transformation of your lifestyle.',
    },
    {
        id: 2,
        vector: '../assets/images/leaf.svg',
        title: 'Weight Gain',
        description: 'How to gain weight? This is a million-dollar question asked by many Indian youngsters today. Diet & Health Center by Dt.Heena is number one diet clinic of Gujarat, assisting their clients with healthy weight gain. If you are sick of being stick thin, then you should incorporate the right foods into your diet plan.',
    },
    {
        id: 3,
        vector: '../assets/images/leaf.svg',
        title: 'Diet for Diabetes',
        description: 'Diabetes and other lifestyle disorders can no longer be ignored, or simply ‘controlled’. The Covid pandemic has shown us just how deadly it can be for people with diabetes, hypertension, obesity, high cholesterol, etc. But you don’t need to suffer.',
    },
    {
        id: 4,
        vector: '../assets/images/leaf.svg',
        title: 'Diet for Hypertension(BP)',
        description: 'Hypertension, or high blood pressure, is a silent condition that can affect overall health. Our customized diet plans use a "food as medicine" approach, focusing on heart-healthy ingredients, reducing sodium, and balancing nutrients to naturally manage blood pressure and support long-term wellness',
    },
    {
        id: 5,
        vector: '../assets/images/leaf.svg',
        title: 'Diet for PCOD/PCOS/Menopause',
        description: 'PCOS/PCOD is a condition that causes a hormonal imbalance in women of reproductive age. Each woman’s PCOS/PCOD symptoms are unique. Maintain a healthy weight and reset hormonal health through our food as medicine approach.',
    },
    {
        id: 6,
        vector: '../assets/images/leaf.svg',
        title: 'Diet For Thyroid',
        description: 'Losing weight can be a frustrating process for many people with an underactive thyroid. Whether you have hypothyroidism or no thyroid after surgery or radioactive iodine (RAI) treatment, there is no question that for many, the thyroid affects metabolism and can make weight loss an uphill battle.',
    },
    {
        id: 7,
        vector: '../assets/images/leaf.svg',
        title: 'Diet for Cardiac Disease',
        description: 'Support your heart health with a specialized diet designed to manage and reduce the risk of cardiac conditions. Our heart-healthy nutrition plans focus on reducing cholesterol, managing blood pressure, and promoting overall cardiovascular wellness.',
    },
    {
        id: 8,
        vector: '../assets/images/leaf.svg',
        title: 'Diet for Kidney Disorders',
        description: 'Manage your kidney health with a carefully structured diet plan tailored to your condition. Our kidney-friendly diets help regulate electrolyte levels, manage fluid intake, and reduce strain on your kidneys.',
    },
    {
        id: 9,
        vector: '../assets/images/leaf.svg',
        title: 'Pre and Post Pregnancy Diet',
        description: 'Ensure both you and your baby receive optimal nutrition during this important time. Our pre- and post-pregnancy diet plans focus on supporting your body’s changing needs, aiding healthy fetal development, and promoting postpartum recovery.',
    },
    {
        id: 10,
        vector: '../assets/images/leaf.svg',
        title: 'Detox Diet',
        description: 'Cleanse your body naturally with our balanced detox diet plans designed to flush out toxins and rejuvenate your system. These carefully crafted diets focus on nutrient-dense foods, hydration, and gentle digestive support.',
    },
    {
        id: 11,
        vector: '../assets/images/leaf.svg',
        title: 'Sports Diet',
        description: 'Enhance your athletic performance and recovery with personalized nutrition plans tailored for sports-specific needs. Our expertly crafted diets optimize energy levels, support muscle growth and repair, and improve endurance.',
    },
    {
        id: 12,
        vector: '../assets/images/leaf.svg',
        title: 'Diet for Kids',
        description: 'Ensure your child’s growth and development are supported with a well-balanced, nutrient-rich diet plan. Our kid-friendly diets are designed to promote healthy eating habits, boost immunity, and provide essential vitamins for their growth.',
    },
];

export default function ServicesSection({ limit, showTitle }) {
    const navigate = useNavigate(); // For navigation

    // Slice services based on the limit; display all if limit is not provided
    const displayedServices = limit ? servicesData.slice(0, limit) : servicesData;

    const handleViewMore = () => {
        navigate('/services'); // Navigate to the Services Page
    };

    const renderServices = () => (
        <div className="row justify-content-center">
            {displayedServices.map((service, index) => {
                // Dynamic image assignment:
                // First 6 services -> Media1 to Media6
                // Next 6 services -> Media7 to Media12
                const imageNumber = index < 6 ? (index % 6) + 1 : (index % 6) + 7;

                return (
                    <div key={service.id} className="col-12 mb-4">
                        <div className="d-flex flex-column flex-md-row">
                            {/* Service Card */}
                            <div className="col-12 col-md-8 mb-3 mb-md-0">
                                <Card className="featureCard mb-3">
                                    <Card.Body>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex justify-content-center align-items-center me-3">
                                                <Card.Img src={service.vector} alt={"icon" + service.id} style={{ height: '25px' }} />
                                            </div>
                                            <Card.Title className="m-0 greenTitle">{service.title}</Card.Title>
                                        </div>
                                        <Card.Text className="pt-2 boldText">{service.description}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>

                            {/* Image */}
                            <div className="col-12 col-md-4">
                                <div className="d-flex justify-content-center">
                                    <img
                                        src={`../assets/images/Media${imageNumber}.png`}
                                        className="img-fluid"
                                        alt={`Media ${imageNumber}`}
                                        style={{ maxHeight: '200px', objectFit: 'cover', width: '90%', borderRadius: '8px' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );

    return (
        <section className="my-5">
            <div className="container">
                {/* Conditionally render the title */}
                {showTitle && (
                    <>
                        <h2 className="text-center">Tailored Nutrition Services</h2>
                        <p className="text-center">Customized plans for your Health Goals</p>
                    </>
                )}

                {/* Render services */}
                {renderServices()}

                {/* "More Services" Button: Only visible when limit is provided */}
                {limit && (
                    <div className="d-flex align-items-center justify-content-center">
                        <Button onClick={handleViewMore} className="roundBtn">
                            More Services
                        </Button>
                    </div>
                )}
            </div>
        </section>
    );
}
