import React,{useState} from 'react'
import logo from "../assets/images/logo.png"
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Button from 'react-bootstrap/esm/Button'
import ConsultantModel from './pricingComponents/consultantModel';

export default function Footer() {
  const [showModal, setShowModal] = useState(false);
  const [step, setStep] = useState(1);

  const handleClick = () => {
    setShowModal(true);
    setStep(1); // Reset step to 1 when opening the modal
  };

  const handleHide = () => {
    setShowModal(false);
    setStep(1); // Reset step to 1 when closing the modal
  };
  return (
    <div className='container'>
        <Row>
            <Col xs={12} sm={12} md={2}><div className='d-flex justify-content-center align-items-center'><img src={logo} alt='logo'/></div></Col>
            <Col xs={12} sm={12} md={7} className='d-flex justify-content-center align-items-center'>
              <div className='d-flex justify-content-center align-items-center flex-wrap'>
                <a className='footerLink' href='/'>Home</a>
                <a className='footerLink' href='/#/about'>About</a>
                <a className='footerLink' href='/#/services'>Services</a>
                <a className='footerLink' href='/#/pricing'>Pricing</a>
                <a className='footerLink' href='/#/testimonials'>Testimonial</a>
                <a className='footerLink' href='/#/blogs'>Blogs</a>
                <a className='footerLink' href='/#/contact'>Contact Us</a>
              </div>
            </Col>
            <Col xs={12} sm={12} md={3} className='d-flex justify-content-center align-items-center'>
              <div className='d-flex justify-content-center align-items-center'>
                <p className='m-0 me-2'>Let's do it! — </p>
                <Button className='squBtn' onClick={handleClick}>
                    Book a Consultation
                </Button>
                <ConsultantModel
                    show={showModal}
                    onHide={handleHide}
                    step={step}
                    setStep={setStep}
                />
              </div>
            </Col>
        </Row>
        <Row>
            <Col sm={12} className='text-center'><small>Made with &#x2764; By <a href='https://api.whatsapp.com/send/?phone=919265084734&text&type=phone_number&app_absent=0' target='_blank' rel='noreferrer'><span style={{color:"black", textDecoration:"none"}} className='fw-semibold'>Renderloop Studios</span></a></small></Col>
        </Row>
    </div>
  )
}
