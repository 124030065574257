import React, { useEffect } from "react";import Intro from './components/intro'
import { AboutHeena } from './components/aboutComponents/aboutHeena'
import {AnimatedNumbers} from './components/aboutComponents/animatedNumbers'
import ClientSays from './components/homeComponents/testimonials'
import clinic from "../src/assets/images/clinic.mp4"
import client2 from "../src/assets/images/clientsays/client2.mp4"
import introbg from "../src/assets/images/AbstractDesign.png"

export const About = () => {
    useEffect(() => {
        const videoElements = document.querySelectorAll("video");
    
        const handleIntersection = (entries) => {
          entries.forEach((entry) => {
            const video = entry.target;
    
            if (entry.isIntersecting) {
              video.play().catch((error) => {
                // Handle play errors silently if needed
                console.log("Play error:", error.message);
              });
            } else {
              video.pause();
            }
          });
        };
    
        const observer = new IntersectionObserver(handleIntersection, {
          threshold: 0.5, // Adjust visibility threshold
        });
    
        // Observe all video elements
        videoElements.forEach((video) => observer.observe(video));
    
        // Cleanup observer on unmount
        return () => {
          videoElements.forEach((video) => observer.unobserve(video));
          observer.disconnect();
        };
      }, []);
    return (
        <React.Fragment>
            <Intro 
                bgImage={introbg}
                title="About Us"
                paragraph="Get to know your nutritionist"
            />
            <AboutHeena />
            <AnimatedNumbers />
            <div className='my-5'>
                <p className='text-center'>Welcome to Diet and Health Centre, where your journey to optimal health begins. Our centre is a warm and welcoming space, designed to make you feel comfortable and supported. Equipped with modern facilities and advanced nutritional tools, we provide a comprehensive range of personalised nutrition services. Our experienced team is dedicated to helping you achieve your health and wellness goals through tailored meal plans, expert advice, and ongoing support. Whether you visit us in person or connect with us online, we are committed to offering the highest quality of care to every client.</p>
            </div>
            <div className='videoWrapper my-5'>
                <video width="100%" height="350" controls>
                    <source src={clinic} type="video/mp4" />
                </video>
            </div>
            <ClientSays videoSource={client2} />
        </React.Fragment>
    )
}