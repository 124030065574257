import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

var featuresData = [
    {
        id: 1,
        vector: '../assets/images/plans.svg',
        title:'Personalized Nutrition Plans',
        description: 'Get a personalized nutrition plan crafted specifically for your body and objectives. Our certified nutritionists will take into account your individual needs, dietary preferences, and health conditions to design a plan that fits you perfectly.',
    },
    {
        id: 2,
        vector: '../assets/images/certified.svg',
        title:'Guidance from Certified Nutritionist',
        description: 'Gain expert advice and personalised support from our certified nutritionists. They will provide you with evidence-based recommendations and strategies to help you achieve and maintain optimal health.',
    },
    {
        id: 3,
        vector: '../assets/images/food.svg',
        title:'Food Tracking and Analysis',
        description: 'Gain valuable insights into your eating habits through our nutritionists meticulous monitoring. They analyze your nutritional habits, helping you understand your eating patterns and nutrient intake for personalized health recommendations.',
    },
    {
        id: 4,
        vector: '../assets/images/recipes.svg',
        title:'Meal Planning and Recipes',
        description: 'Simplify your meal preparation with our expertly designed meal plans and delicious, healthy recipes. Whether you have specific dietary needs or just want to eat better, our resources make healthy eating easy and enjoyable.',
    },
    {
        id: 5,
        vector: '../assets/images/coaching.svg',
        title:'Lifestyle and Behavior Coaching',
        description: 'Transform your habits and achieve lasting change with our lifestyle and behavior coaching. Learn practical strategies to overcome challenges, develop a healthier relationship with food, and enhance your overall well-being.',
    },
    {
        id: 6,
        vector: '../assets/images/products.svg',
        title:'Nutritional Products',
        description: 'Explore our selection of high-quality nutritional products designed to support your health journey. Our products are carefully chosen to help you achieve your nutritional goals with specialized dietary aids and essentials.',
    }
]
export default function FeaturSection () {
    return (
        <section className='my-5'>
            <div className='container'>
                <h2 className='text-center'>Why <span className="greenTxt">Diet and Health Center </span>?</h2>
                <p className='text-center'>Personalized Nutrition Plans | Expert Guidance | Continuous Support</p>
                <Row>
                    {
                        featuresData.map(feature => {
                            return(
                                <Col md={6} key={feature.id} className=''>
                                    <Card key={feature.id} className='my-2 featureCard'>
                                        <Card.Body>
                                            <div className="d-flex align-items-center">
                                                <div className="d-flex justify-content-center align-items-center iconBox me-3">
                                                    <Card.Img src={feature.vector} alt={"icon" + feature.id} />
                                                </div>
                                                <Card.Title>{feature.title}</Card.Title>
                                            </div>
                                            <Card.Text className='pt-2'>
                                                {feature.description}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
        </section>
    )
 }