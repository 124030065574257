import React, { useEffect } from "react";
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

var clientsViews = [
    {
        id: 1,
        name: 'Erfan Patel',
        rating: 5,
        says: 'With Dt. Heenas diet plan I lost around 3 Kgs in 1 month. She has been very accommodating with my demands .... she customized my diet so that I could eat things that I like. Her diet has helped me change my unhealthy life-style to a much healthier one'
    },
    {
        id: 2,
        name: 'Mrunali Parikh',
        rating: 5,
        says: 'She is perfect dietician ,and I have benefited a lot, by following diet as per her instructions,  I  strongly suggest to follow her diet if you wish to conduct any dietician, her charges are also  reasonable compared to other dieticians.'
    },
    {
        id: 3,
        name: 'Jaisee Mehta',
        rating: 5,
        says: 'For Pre natal pregnancy diet i approached dt. Heena parekh. She was effortless and gave me very specific diet which helped increase my pregnancy and baby birth wright in a systematic manner. I had low iron and protein issues which got resolved with her planned diet. Thank yoh so much!'
    },
    {
        id: 4,
        name: 'Viraj Shah',
        rating: 5,
        says: '*Excellent Nutritionist: Results-Driven Support* Highly recommend Diet & Health Centre Their personalised meal plans and weekly follow-ups were key to our success. I lost nearly 10 kilograms in less than 3 months thanks to their expert guidance and support.'
    },
    {
        id: 5,
        name: 'Erfan Patel',
        rating: 5,
        says: 'With Dt. Heenas diet plan I lost around 3 Kgs in 1 month. She has been very accommodating with my demands .... she customized my diet so that I could eat things that I like. Her diet has helped me change my unhealthy life-style to a much healthier one'
    },
    {
        id: 6,
        name: 'Mrunali Parikh',
        rating: 5,
        says: 'She is perfect dietician ,and I have benefited a lot, by following diet as per her instructions,  I  strongly suggest to follow her diet if you wish to conduct any dietician, her charges are also  reasonable compared to other dieticians.'
    },
    {
        id: 7,
        name: 'Jaisee Mehta',
        rating: 5,
        says: 'For Pre natal pregnancy diet i approached dt. Heena parekh. She was effortless and gave me very specific diet which helped increase my pregnancy and baby birth wright in a systematic manner. I had low iron and protein issues which got resolved with her planned diet. Thank yoh so much!'
    },
    {
        id: 8,
        name: 'Viraj Shah',
        rating: 5,
        says: '*Excellent Nutritionist: Results-Driven Support* Highly recommend Diet & Health Centre Their personalised meal plans and weekly follow-ups were key to our success. I lost nearly 10 kilograms in less than 3 months thanks to their expert guidance and support.'
    }
];

export default function ClientSays({videoSource}) {
    useEffect(() => {
        const videoElements = document.querySelectorAll("video");
    
        const handleIntersection = (entries) => {
          entries.forEach((entry) => {
            const video = entry.target;
    
            if (entry.isIntersecting) {
              video.play().catch((error) => {
                // Handle play errors silently if needed
                console.log("Play error:", error.message);
              });
            } else {
              video.pause();
            }
          });
        };
    
        const observer = new IntersectionObserver(handleIntersection, {
          threshold: 0.5, // Adjust visibility threshold
        });
    
        // Observe all video elements
        videoElements.forEach((video) => observer.observe(video));
    
        // Cleanup observer on unmount
        return () => {
          videoElements.forEach((video) => observer.unobserve(video));
          observer.disconnect();
        };
      }, []);
    const renderStars = (rating) => {
        return Array.from({ length: 5 }, (_, index) => (
            <i key={index} className={`fas fa-star ${index < rating ? 'text-warning' : 'text-muted'}`} />
        ));
    };
    return (
        <section id="testimonials" className='my-5'>
            <div className='container'>
                <h2 className='text-center'>What Our Client Says!</h2>
                <p className='text-center'>Our satisfied clients share their success stories and experiences on their journey to better health and well-being.</p>
                <Row className="d-flex justify-content-center align-items-center">
                    <Col sm={12} md={4}>
                        <Carousel data-bs-theme="dark" controls={false}>
                            {clientsViews.reduce((acc, client, index) => {
                                // Create a new Carousel item every two cards
                                if (index % 2 === 0) {
                                    acc.push(
                                        <Carousel.Item key={index}>
                                            <Row className='d-flex justify-content-center align-items-center'>
                                                <Col xs={12} className='mb-4'>
                                                    <Card className='greenCard'>
                                                        <Card.Body>
                                                            <Card.Text className='min-vh-25'>
                                                                {client.says}
                                                            </Card.Text>
                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                <div><h4 className='clientName'>{client.name}</h4></div>
                                                                <div>{renderStars(client.rating)}</div>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                {index + 1 < clientsViews.length && (
                                                    <Col xs={12} className='mb-4'>
                                                        <Card className='greenCard'>
                                                            <Card.Body>
                                                                <Card.Text className='min-vh-25'>
                                                                    {clientsViews[index + 1].says}
                                                                </Card.Text>
                                                                <div className='d-flex justify-content-between align-items-center'>
                                                                    <div><h4 className='clientName'>{clientsViews[index + 1].name}</h4></div>
                                                                    <div>{renderStars(clientsViews[index + 1].rating)}</div>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                )}
                                            </Row>
                                        </Carousel.Item>
                                    );
                                }
                                return acc;
                            }, [])}
                        </Carousel>
                    </Col>
                    <Col sm={12} md={4}>
                        <div className='videoWrapper'>
                            <video width="100%" height="100%" controls>
                                <source src={videoSource} type="video/mp4" />
                            </video>
                        </div>
                    </Col>
                </Row>
            </div>
            {/* <div className="carousel-controls">
                <button className="carousel-control-prev" type="button" data-bs-target="#carousel" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carousel" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="">Next</span>
                </button>
            </div> */}
        </section>
    );
}
