import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

// Sample product data
const products = [
    { id: 1, name: 'Aloevera Juice', image: '../../assets/images/products/aloevera_juese.jpg' },
    { id: 2, name: 'Amla Juice', image: '../../assets/images/products/amla_juice.jpg' },
    { id: 3, name: 'Ashwagandha Roots Powder', image: '../../assets/images/products/ashwagandharoots_powder.jpg' },
    { id: 4, name: 'Desi Protine Powder', image: '../../assets/images/products/desi_protine_powder.jpg' },
    { id: 5, name: 'Flex seeds', image: '../../assets/images/products/flexseeds.jpg' },
    { id: 6, name: 'Health care', image: '../../assets/images/products/healthcare.jpg' },
    { id: 7, name: 'Kalonji seeds', image: '../../assets/images/products/kalonjiseeds.jpg' },
    { id: 8, name: 'Pumpkin seeds', image: '../../assets/images/products/pumpkinseeds.jpg' },
    { id: 9, name: 'Quinoa seeds', image: '../../assets/images/products/quinoaseeds.jpg' },
    { id: 10, name: 'Shatavari Churan', image: '../../assets/images/products/shatavari_churan.jpg' },
    { id: 11, name: 'Sunflower seeds', image: '../../assets/images/products/sunflowerseeds.jpg' },
    { id: 12, name: 'Triphala Powder', image: '../../assets/images/products/triphala_powder.jpg' },
    { id: 13, name: 'Wheatgrass Powder', image: '../../assets/images/products/wheatgrass_powder.jpg' },
];

export const Products = () => {
    return (
        <Container>
            <div className='d-flex justify-content-center align-items-center'>
                <h1 className='mb-5'>Our Products</h1>
            </div>
            <Row>
                {products.map(product => (
                    <Col xs={6} sm={6} md={4} lg={3} key={product.id} className="mb-4">
                        <Card>
                            <Card.Img
                                variant="top"
                                src={product.image}
                                alt={product.name}
                                style={{ objectFit: 'contain', height: window.innerWidth < 768 ? '150px' : '350px' }}
                            />
                            <Card.Body className='productNameStyle text-center'>
                                <Card.Title style={{ fontSize: '1rem' }}>{product.name}</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};
