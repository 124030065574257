import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom'; // Updated to use Routes
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'font-awesome/css/font-awesome.min.css';
import './App.css';
import { Home } from './Home';
import { About } from './About';
import { Services } from './Services';
import { Pricing } from './Pricing';
import { Testimonials } from './Testimonials';
import { Contact } from './Contact';
import { NoMatch } from './NoMatch'; // Add this for handling 404s
import { Blogs } from './Blogs';
import { Layout } from './components/layout';
import AppHeader from './components/header';
import Footer from './components/footer';
import { WaFloating } from './components/wafloating';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <React.Fragment>
      <Layout>
        <Router>
          <ScrollToTop />
          <AppHeader />
          <Routes> {/* Replaced Switch with Routes */}
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/testimonials" element={<Testimonials />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<NoMatch />} /> {/* For unmatched routes */}
          </Routes>
        </Router>
      </Layout>
      <footer id="footer">
        <Footer />
      </footer>
      <WaFloating />
    </React.Fragment>
  );
}

export default App;
