import React from 'react'
import Intro from './components/intro'

export const NoMatch = () => (
  <React.Fragment>
        <Intro 
            bgImage="https://example.com/path/to/image2.jpg"
            title="About Us"
            paragraph="Get to Know Your Nutrition Expert Discover the passion and experience behind our personalized nutrition services."
        />
    </React.Fragment>
)