import React, { useState } from "react";
import "./BlogGrid.css";

const BlogGrid = () => {
  const blogs = [
    {
      id: 1,
      image: "/assets/images/blog1.png",
      title: "How to Maintain a Healthy Weight: A Comprehensive Guide",
      date: "December 18, 2024",
      shortDescription:
        "Traveling is an enriching experience that opens up new horizons, exposes us to different cultures, and creates memories that last a lifetime. However, it can also be stressful if you don't plan adequately.",
      detailDescription: [
        {
          subtitle: "Follow a Balanced Diet",
          content: [
            "A balanced diet is the cornerstone of maintaining a healthy weight. Ensure your meals include:",
            "Whole grains: Opt for brown rice, whole-wheat bread, and quinoa over refined grains.",
            "Lean proteins: Incorporate sources like chicken, fish, beans, and tofu to keep you full and energized.",
            "Healthy fats: Include avocados, nuts, seeds, and olive oil in moderation.",
            "Fruits and vegetables: Aim for a variety of colors on your plate to get a range of nutrients."
          ]
        },
        {
          subtitle: "Practice Portion Control",
          content:
            "Even healthy foods can contribute to weight gain if consumed in large quantities. Be mindful of portion sizes: Use smaller plates to avoid overeating. Learn to listen to your body’s hunger and fullness cues. Avoid eating directly from large packages or containers.",
        },
        {
          subtitle: "Stay Hydrated",
          content:
            "Often, thirst is mistaken for hunger. Drinking plenty of water throughout the day can help curb unnecessary snacking. Aim for at least 8-10 glasses of water daily, and consider herbal teas for variety.",
        },
        {
          subtitle: "Be Physically Active",
          content:
            "Regular exercise is essential for maintaining a healthy weight. Find activities you enjoy to make it sustainable: Engage in aerobic exercises like walking, jogging, or cycling. Incorporate strength training to build muscle and boost metabolism. Stay active during the day by taking short walks, using stairs, or stretching.",
        },
        {
          subtitle: "Get Quality Sleep",
          content:
            "Lack of sleep can disrupt hormones that regulate hunger and metabolism, leading to weight gain. Aim for 7-9 hours of quality sleep each night. Establish a bedtime routine, avoid screens before bed, and create a calming sleep environment.",
        },
        {
          subtitle: "Manage Stress Effectively",
          content:
            "Stress can trigger emotional eating and cravings for unhealthy foods. Practice stress-reducing techniques like: Yoga or meditation, Deep breathing exercises, Journaling or spending time in nature",
        },
        {
          subtitle: "Limit Processed Foods and Sugary Beverages",
          content:
            "Highly processed foods and sugary drinks are calorie-dense but low in nutrients. Swap them for healthier alternatives: Replace soda with infused water or herbal tea. Opt for homemade snacks instead of packaged options.",
        },
        {
          subtitle: "Monitor Your Progress",
          content:
            "Track your food intake, physical activity, and weight to stay accountable. Use apps or maintain a journal to identify patterns and make necessary adjustments.",
        },
        {
          subtitle: "Seek Professional Guidance",
          content: "If you struggle to maintain a healthy weight, consult a registered dietitian or nutritionist. They can create a personalized plan tailored to your specific needs and lifestyle.",
        },
        {
          subtitle:"Final Thoughts",
          content: "Maintaining a healthy weight is a lifelong commitment to making mindful choices every day. By following these tips and staying consistent, you can enjoy improved health, increased energy, and a better quality of life. Remember, small, sustainable changes often lead to the best long-term results."
        }
      ],
    },
    {
      id: 2,
      image: "/assets/images/blog2.png", 
      title: "5 Superfoods to Control Type 2 Diabetes",
      date: "December 18, 2024",
      shortDescription:
        "Managing Type 2 Diabetes requires a balanced approach to diet, exercise, and lifestyle. Including certain superfoods in your daily meals can help regulate blood sugar levels, improve insulin sensitivity, and promote overall health. Here are five powerful superfoods that are particularly beneficial for individuals with Type 2 Diabetes:",
      detailDescription: [
        {
          subtitle: "1. Methi Seeds (Fenugreek Seeds)",
          content:
            "Methi seeds are rich in soluble fiber, which slows down the absorption of carbohydrates and sugars in the digestive system. This helps to stabilize blood sugar levels post-meal. How to Use: Soak 1-2 teaspoons of methi seeds in water overnight and consume them on an empty stomach in the morning. You can also add powdered methi seeds to curries and flatbreads for added health benefits.",
        },
        {
          subtitle: "2. Amla (Indian Gooseberry)",
          content: "Amla is packed with Vitamin C and antioxidants, which can help regulate pancreatic function and improve insulin sensitivity. It also has anti-inflammatory properties, making it an excellent choice for managing diabetes. How to Use: Consume fresh amla juice daily, or add amla powder to smoothies or herbal teas. It can also be eaten raw or pickled.",
        },
        {
          subtitle: "3. Apple Cider Vinegar",
          content:"Apple cider vinegar (ACV) is known to improve insulin sensitivity and lower fasting blood sugar levels. It works by reducing the digestion of starch, which helps prevent sudden blood sugar spikes. How to Use: Mix 1-2 tablespoons of ACV with a glass of water and drink it before meals. Ensure to dilute it to avoid acidity or stomach discomfort."
        },
        {
          subtitle: "4. Bitter Gourd Juice",
          content: "Bitter gourd (karela) contains compounds like polypeptide-p and charantin, which have blood sugar-lowering properties. It helps activate the pancreas and improve insulin secretion, making it highly effective for managing diabetes. How to Use: Drink a small glass of fresh bitter gourd juice on an empty stomach in the morning. You can also include bitter gourd as a vegetable in your meals."
        },
        {
          subtitle: "5. Green Leafy Vegetables",
          content:"Leafy greens like spinach, kale, and fenugreek leaves are low in calories and high in fiber, vitamins, and minerals. They have a minimal impact on blood sugar levels and are rich in magnesium, which helps improve insulin sensitivity. How to Use: Incorporate green leafy vegetables into salads, soups, and stir-fries. You can also blend them into smoothies for a nutrient-packed drink."
        },
        {
          subtitle: "Additional Tips for Managing Diabetes with Superfoods",
          content:"Stay Consistent: Consistency is key when incorporating these superfoods into your diet. Make them a regular part of your meals to see long-term benefits. Hydration Matters: Drinking plenty of water throughout the day can enhance the effectiveness of these superfoods and support better digestion and metabolism. Avoid Processed Foods: Complement your superfood intake by reducing or eliminating processed foods, sugary beverages, and trans fats from your diet. Portion Control: Even healthy foods can be detrimental if consumed in excess. Practice portion control to manage calorie intake effectively."
        },
        {
          subtitle: "Why Superfoods Are Essential",
          content: "Superfoods are nutrient-dense, meaning they pack a lot of nutrients into a small number of calories. For individuals with Type 2 Diabetes, this is especially important, as maintaining a nutrient-rich diet without overloading on calories is crucial. They not only aid in blood sugar management but also support heart health, reduce inflammation, and promote weight management—all of which are vital in diabetes care."
        },
        {
          subtitle: "Final Thoughts",
          content: "Incorporating these superfoods into your diet can significantly help manage Type 2 Diabetes by stabilizing blood sugar levels and improving overall health. However, it’s essential to maintain a balanced diet and consult a healthcare professional or dietitian before making significant dietary changes. Small, consistent steps toward healthy eating can make a big difference in controlling diabetes and enhancing your quality of life. Take charge of your health today by embracing these superfoods and adopting a holistic approach to diabetes management."
        },
      ],
    },
  ];

  const [selectedBlog, setSelectedBlog] = useState(null);

  const handleReadMore = (blog) => {
    setSelectedBlog(blog);
  };

  const handleBackToGrid = () => {
    setSelectedBlog(null);
  };

  return (
    <div className="blog-container">
      {selectedBlog ? (
        <React.Fragment>
          <button onClick={handleBackToGrid} className="back-button">
              Back
            </button>
          <div className="blog-detail">
            <h1 className="blog-title">{selectedBlog.title}</h1>
            <p className="blog-date">{selectedBlog.date}</p>
            <img
              src={selectedBlog.image}
              alt={selectedBlog.title}
              className="blog-image-large"
            />
            <div style={{ marginTop: '20px' }}>
              <p className="blog-description">{selectedBlog.shortDescription}</p>
              {selectedBlog.detailDescription.map((section, index) => (
                <div key={index} className="blog-section">
                  <h3 className="blog-subtitle">{section.subtitle}</h3>
                  <p>{section.content}</p>
                </div>
              ))}
            </div>
          </div>
        </React.Fragment>
      ) : (
        <div className="blog-grid">
          {blogs.map((blog) => (
            <div key={blog.id} className="blog-card">
              <img src={blog.image} alt={blog.title} className="blog-image" />
              <h2 className="blog-card-title">{blog.title}</h2>
              <p className="blog-card-date">{blog.date}</p>
              <p className="blog-card-description">{blog.shortDescription}</p>
              <button
                onClick={() => handleReadMore(blog)}
                className="read-more-button"
              >
                Read More
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BlogGrid;
