import React from 'react';
import Container from 'react-bootstrap/esm/Container';

const Intro = ({ bgImage, title, paragraph }) => {
  return (
    <div className="intro mb-3" style={{ backgroundImage: `url(${bgImage})`, marginTop: '150px', backgroundPosition: 'center', backgroundSize: 'contain', backgroundRepeat:'no-repeat', backgroundColor:'#F6FBE9' }}>
      <Container>
        <div className="d-flex align-items-center justify-content-center flex-column p-5 overlay">
          <h1 className=''>{title}</h1>
          <p className='text-center'>{paragraph}</p>
        </div>
      </Container>
    </div>
  );
};

export default Intro;
