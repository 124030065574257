import Accordion from 'react-bootstrap/Accordion';

var faqs = [
    {
        id:1,
        que: 'What services do you offer?',
        ans: 'We offer a range of personalized nutrition services, including weight loss and gain, diets for therapeutic disorders, post-pregnancy weight loss, nutrition for mothers and babies, special diets for grooms and brides, nutrition for children, diets for PCOS and thyroid, and sports nutrition.'
    },
    {
        id:2,
        que: 'How do I get started with a nutrition plan?',
        ans: 'To get started, you can schedule an initial consultation through our website. During this session, we will discuss your health goals, dietary preferences, and any medical conditions to create a personalized nutrition plan tailored to your needs.'
    },
    {
        id:3,
        que: 'Do you offer virtual consultations?',
        ans: 'Yes, we offer virtual consultations to accommodate your schedule and location. Our nutritionists can provide the same level of personalized support and guidance through online sessions.'
    },
    {
        id:4,
        que: 'How often will I need to meet with a nutritionist?',
        ans: 'The frequency of consultations varies based on your individual needs and goals. Typically, clients start with weekly or bi-weekly sessions and then move to monthly check-ins as they progress. We will work with you to determine the best schedule.'
    },
    {
        id:5,
        que: 'What should I expect during my first consultation?',
        ans: 'During your first consultation, we will conduct a comprehensive assessment of your health history, dietary habits, lifestyle, and goals. This information will help us create a customized nutrition plan. You will also have the opportunity to ask any questions you may have.'
    },
    {
        id:6,
        que: 'Can you help with specific dietary needs or medical conditions?',
        ans: 'Yes, our nutritionists are experienced in creating tailored plans for a variety of dietary needs and medical conditions, including diabetes, PCOS, thyroid disorders, digestive issues, and more. We will work closely with you to address your specific concerns.'
    },
    {
        id:7,
        que: 'Will I receive meal plans and recipes?',
        ans: 'Yes, we provide personalized meal plans and recipes that align with your nutritional goals and preferences. Our goal is to make healthy eating enjoyable and sustainable for you.'
    },
    {
        id:8,
        que: 'How can I track my progress?',
        ans: 'We encourage clients to keep a food diary and use tracking tools to monitor their dietary intake and progress. During follow-up consultations, we will review your progress, make necessary adjustments to your plan, and provide ongoing support.'
    },
    {
        id:9,
        que: 'How do I contact you for support between consultations?',
        ans: 'You can contact us via email or through the client portal on our website. We are here to support you and answer any questions you may have between your scheduled consultations.'
    },
]

export const Faq = () => {
    return (
        <section className='my-5'>
            <div className='container'>
                <h2 className='text-center'>Frequently Asked Questions</h2>
                <p className='text-center'>Proper nutrition is essential for a healthy life. Enhance your well-being by adhering to a balanced meal plan.</p>
                <div>
                    <Accordion defaultActiveKey="0" className='customAccordion'>
                        {faqs.map((faq, index) => (
                            <Accordion.Item eventKey={faq.id} key={faq.id}>
                                <Accordion.Header>{faq.que}</Accordion.Header>
                                <Accordion.Body>{faq.ans}</Accordion.Body>
                        </Accordion.Item>
                        ))}
                    </Accordion>
                </div>
            </div>
        </section>
    )
}