import React, { useEffect } from "react";
import inchLoss from "../../assets/images/inchLoss.mp4";

export default function DiscoverSeaction () {
    useEffect(() => {
        const videoElements = document.querySelectorAll("video");
    
        const handleIntersection = (entries) => {
          entries.forEach((entry) => {
            const video = entry.target;
    
            if (entry.isIntersecting) {
              video.play().catch((error) => {
                // Handle play errors silently if needed
                console.log("Play error:", error.message);
              });
            } else {
              video.pause();
            }
          });
        };
    
        const observer = new IntersectionObserver(handleIntersection, {
          threshold: 0.5, // Adjust visibility threshold
        });
    
        // Observe all video elements
        videoElements.forEach((video) => observer.observe(video));
    
        // Cleanup observer on unmount
        return () => {
          videoElements.forEach((video) => observer.unobserve(video));
          observer.disconnect();
        };
      }, []);

    return (
        <section className='my-5'>
            <div className='container'>
                <h2 className='text-center'>Discover Our <span className="greenTxt">Inch Loss Machine</span></h2>
                <p className='text-center'>Revitalize Your Body with Advanced Inch Loss Technology</p>
                <div className="row"> 
                    <div className='col-xs-12 col-sm-12 col-md-6'>
                        <video width="100%" height="350" controls>
                            <source src={inchLoss} type="video/mp4" />
                        </video>
                    </div>
                    <div className='col-xs-12 col-sm-12 col-md-6 d-flex justify-content-center align-items-center flex-column'>
                        <p>Experience our state-of-the-art inch loss machine, a 4-step session designed to help you achieve your body contouring goals efficiently. Each 1-hour session targets specific areas, using advanced technology to promote inch loss and enhance skin elasticity. <span>Our machine utilizes ultrasound cavitation therapy to destroy fat cells, combined with RF (radio frequency) technology to tighten and rejuvenate the skin. This non-invasive treatment breaks down fat deposits, allowing your body to naturally eliminate them, while also stimulating collagen production for firmer, smoother skin. Whether you're looking to tone up, reduce cellulite, or improve overall body shape, our machine offers effective results in a relaxing and rejuvenating environment.</span>
                        </p>
                        {/* <button className='roundBtn'>Know More</button> */}
                    </div>
                </div>
            </div>
        </section>
    )
}