import React, { useState } from 'react';
import { Form, Row, Col, Button, Container } from 'react-bootstrap';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Intro from './components/intro';
import introbg from "../src/assets/images/AbstractDesign.png"

export const Contact = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.firstName) newErrors.firstName = 'First name is required.';
        if (!formData.lastName) newErrors.lastName = 'Last name is required.';
        if (!formData.email) {
            newErrors.email = 'Email is required.';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Email address is invalid.';
        }
        if (!formData.phone) newErrors.phone = 'Phone number is required.';
        if (!formData.message) {
            newErrors.message = 'Message is required.';
        } else if (formData.message.length > 100) {
            newErrors.message = 'Message cannot exceed 100 characters.';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            // Send email using EmailJS
            emailjs.send('service_2gjepse', 'template_wj533tl', formData, 'w5u7sRboS0gCKJz0o')
                .then((response) => {
                    toast('Message sent successfully!');
                    // Reset the form
                    setFormData({
                        firstName: '',
                        lastName: '',
                        email: '',
                        phone: '',
                        message: '',
                    });
                    setErrors({});
                })
                .catch((err) => {
                    console.error('Failed to send email:', err);
                });
        }
    };
    return(
        <div id="mainContent" className=''>
            <Intro 
                bgImage={introbg}
                title="Contact Us"
                paragraph="Get in Touch with Us for Any Queries or Support"
            />
            <Container className=''>
                <div className='contact bg-white p-3'>
                    <Row>
                        <Col sm={12} md={4}>
                            <div className='greenContactCard p-lg-5 p-md-4 p-3'>
                                <h3 className='mb-5'>Contact Information</h3>
                                <div className='d-flex justify-content-start align-items-start my-2'>
                                    <img src="/assets/images/phone.svg" alt='phone' className='contactIcons' />
                                    <a className='m-0 pb-2 me-2 text-light' href='tel:96017 20668' style={{ textDecoration:'none' }}>+91 96017 20668 </a> , <a className='m-0 pb-2 text-light' style={{ textDecoration:'none' }} href='tel:96629 28140'>+91 96629 28140</a>
                                </div>
                                <div className='d-flex justify-content-start align-items-start my-2'>
                                    <img src="/assets/images/mail.svg" alt='mail' className='contactIcons' style={{ width:"20px", height:"20px" }} />
                                    <a href='mailto:dietandhealthcenter22@gmail.com' style={{ color:"white", textDecoration:"none" }}>dietandhealthcenter22@gmail.com</a>
                                </div>
                                <div className='d-flex justify-content-start align-items-start my-2'>
                                    <img src="/assets/images/locationPin.svg" alt='address' className='contactIcons' />
                                    <p>Diet and health center, Emerald One Complex, C369, Jetalpur Rd, beside Gujarat Kidney Hospital, Vadodara, Gujarat 390020</p>
                                </div>
                                <div className='iframe-rwd'>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14764.951165154383!2d73.1765283!3d22.3068449!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395fc911d909e9d1%3A0xccd96ca428f838ea!2sDiet%20%26%20Health%20Center%20-%20Nutritionist%20%26%20Dietitian%20in%20Vadodara!5e0!3m2!1sen!2sin!4v1704698707250!5m2!1sen!2sin" title='location' width="340" height="250" style={{ border:0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                                <div className='d-flex justify-content-center align-items-center py-5 socialMedia'>
                                    <div className='me-4'>
                                        <a href='https://www.facebook.com/share/ndL9Yq66WumWo7TS/?mibextid=qi2Omg' target="_blank" rel='noreferrer'><img src="/assets/images/facebook.svg" alt='facebook' /></a></div>
                                    <div className='me-4'><a href='https://www.instagram.com/dhc_vadodara?igsh=MXAxOGl1eG1hcGh2Zw==' target="_blank" rel='noreferrer'><img src="/assets/images/instagram.svg" alt='instagram' /></a></div>
                                    <div className='me-4'><a href='https://api.whatsapp.com/send/?phone=919601720668&text&type=phone_number&app_absent=0' target="_blank" rel='noreferrer'><img src="/assets/images/whatsapp.svg" alt='whatsapp' /></a></div>
                                    <div className='me-4'><a href='https://www.youtube.com/@diethealthcenter7180' target="_blank" rel='noreferrer'><img src="/assets/images/youtube.svg" alt='youtube' /></a></div>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} md={8}>
                            <h4 className='text-center my-5'>We are a dedicated nutrition firm passionate about holistic wellness. Our delicious and nutritious meal plans are designed to inspire healthier lifestyle changes and help you achieve your wellness goals. Join us on a journey towards optimal health and well-being.</h4>
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col className='mb-3'>
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control
                                            name="firstName"
                                            placeholder="First name"
                                            className='customInput'
                                            value={formData.firstName}
                                            onChange={handleChange}
                                        />
                                        {errors.firstName && <div className="text-danger">{errors.firstName}</div>}
                                    </Col>
                                    <Col className='mb-3'>
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control
                                            name="lastName"
                                            placeholder="Last name"
                                            className='customInput'
                                            value={formData.lastName}
                                            onChange={handleChange}
                                        />
                                        {errors.lastName && <div className="text-danger">{errors.lastName}</div>}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='mb-3'>
                                        <Form.Label>Email Address</Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            placeholder="Email"
                                            className='customInput'
                                            value={formData.email}
                                            onChange={handleChange}
                                        />
                                        {errors.email && <div className="text-danger">{errors.email}</div>}
                                    </Col>
                                    <Col className='mb-3'>
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control
                                            type="tel"
                                            name="phone"
                                            placeholder="+1 012 3456 789"
                                            className='customInput'
                                            value={formData.phone}
                                            onChange={handleChange}
                                        />
                                        {errors.phone && <div className="text-danger">{errors.phone}</div>}
                                    </Col>
                                </Row>
                                <Row>
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="message"
                                        placeholder='Write your message..'
                                        rows={2}
                                        className='customInput'
                                        maxLength={100}
                                        value={formData.message}
                                        onChange={handleChange}
                                    />
                                    {errors.message && <div className="text-danger">{errors.message}</div>}
                                </Row>
                                <Button variant="primary" type="submit" className='float-end my-3 squBtn'>
                                    Send Message
                                </Button>
                                <ToastContainer />
                            </Form>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}
