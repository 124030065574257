import React from 'react'
import Container from 'react-bootstrap/Container';
import HeroSection from './components/homeComponents/herosection';
import FeaturSection from './components/homeComponents/features';
import ServicesSection from './components/homeComponents/services';
import DiscoverSeaction from './components/homeComponents/discover';
import ClinetSays from './components/homeComponents/testimonials';
import {Faq} from './components/homeComponents/faq';
import client1 from "../src/assets/images/clientsays/client1.mp4"

export const Home = () => (
    <div className='App'>
        <main id="mainContent">
          <HeroSection></HeroSection>
          <Container>
            <FeaturSection></FeaturSection>
            <ServicesSection limit={6} showTitle={true}></ServicesSection>
            <DiscoverSeaction></DiscoverSeaction>
          </Container>
          <ClinetSays videoSource={client1} />
          <Faq></Faq>
        </main>
    </div>
)
