import React from 'react';
import 'font-awesome/css/font-awesome.min.css'; // Ensure font-awesome is imported

export const WaFloating = () => {
    return (
        <div>
            <a href="https://api.whatsapp.com/send/?phone=919601720668&text&type=phone_number&app_absent=0" 
               className="float" 
               target="_blank" 
               rel="noreferrer">
                <i className="fa fa-whatsapp my-float"></i>
            </a>
        </div>
    );
}
