import React, { useState } from 'react';
import { Form, Row, Col, Button, Modal } from 'react-bootstrap';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from "../../assets/images/logo.png";

export default function ConsultantModel({ show, onHide, step, setStep }) {
  
  const handleNext = () => {
    setStep(step + 1); // Move to the next step
  };

  const [formData, setFormData] = useState({
    fullName: '',
    contactNumber: '',
    goal: '',
    height: '',
    healthIssues: '',
    emailId: '',
    dob: '',
    gender: '',
    preferredTimings: '',
    message: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Only validate Full Name and Contact Number for Step 1 and Step 2
  const validateStep1 = () => {
    const newErrors = {};
    if (!formData.fullName) newErrors.fullName = 'Full name is required.';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateStep2 = () => {
    const newErrors = {};
    if (!formData.contactNumber) newErrors.contactNumber = 'Contact number is required.';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCreatePlan = () => {
    if (!validateStep2()) return; // Only validate step 2

    // Logic to send email with formData
    emailjs.send('service_2gjepse', 'template_gzqy8sp', formData, 'w5u7sRboS0gCKJz0o')
      .then((response) => {
        toast.success('Your plan has been created successfully!');
        setStep(3); // Move to step 3
      })
      .catch((error) => {
        toast.error('Failed to send the email. Please try again.');
      });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered 
    >
      <Modal.Body>
        <div className='w-50'>
          <div className='d-flex justify-content-center align-items-center flex-column'>
            <img src={logo} alt='logo' />
            <h3 style={{ color: '#6CAF5D' }} className='my-3'>Welcome to Diet and Health Center</h3>
          </div>
          <div>
            {step === 1 && (
              <div>
                <h4 className='text-center'>Fill up your details</h4>
                <Form>
                  <Form.Group className="mb-3" controlId="formGridAddress1">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      name="fullName"
                      value={formData.fullName}
                      onChange={handleChange}
                      placeholder="Enter your name here"
                    />
                    {errors.fullName && <div className="text-danger">{errors.fullName}</div>}
                  </Form.Group>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridAddress2">
                      <Form.Label>Current weight</Form.Label>
                      <Form.Control
                        name="currentWeight"
                        value={formData.currentWeight}
                        onChange={handleChange}
                        type="text"
                        placeholder="Enter your current weight in kgs"
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridGoal">
                      <Form.Label>Your goal</Form.Label>
                      <Form.Control
                        name="goal"
                        value={formData.goal}
                        onChange={handleChange}
                        type="text"
                        placeholder="Enter your desired weight in kgs"
                      />
                    </Form.Group>
                  </Row>
                  <Form.Group className="mb-3" controlId="formGridHeight">
                    <Form.Label>Height</Form.Label>
                    <Form.Control
                      name="height"
                      value={formData.height}
                      onChange={handleChange}
                      type="text"
                      placeholder="Enter your height in feet"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formGridHealthIssues">
                    <Form.Label>Any Health Issues?</Form.Label>
                    <Form.Control
                      name="healthIssues"
                      value={formData.healthIssues}
                      onChange={handleChange}
                      type="text"
                      placeholder="Enter your health problems"
                    />
                  </Form.Group>
                  <div className='d-flex justify-content-between'>
                    <Button className='squBtn w-100' onClick={handleNext}>Next</Button>
                    <Button className='position-absolute closeBtn' onClick={onHide}>
                      <i className="bi bi-x-circle-fill" style={{ fontSize: '32px' }}></i>
                    </Button>
                  </div>
                </Form>
              </div>
            )}
            {step === 2 && (
              <div>
                <Form>
                  <Form.Group className="mb-3" controlId="formGridGoal">
                    <Form.Label>Contact Number</Form.Label>
                    <Form.Control
                      name="contactNumber"
                      value={formData.contactNumber}
                      onChange={handleChange}
                      type="text"
                      placeholder="Enter your contact number"
                    />
                    {errors.contactNumber && <div className="text-danger">{errors.contactNumber}</div>}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formGridHeight">
                    <Form.Label>Email Id</Form.Label>
                    <Form.Control
                      name="emailId"
                      value={formData.emailId}
                      onChange={handleChange}
                      type="email"
                      placeholder="Enter your email id"
                    />
                  </Form.Group>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridAddress2">
                      <Form.Label>Date of birth</Form.Label>
                      <Form.Control
                        name="dob"
                        value={formData.dob}
                        onChange={handleChange}
                        type="date"
                      />
                    </Form.Group>
                    <Form.Group as={Col} id="formGridCheckbox">
                      <Form.Label>Gender</Form.Label>
                      <div className='d-flex' style={{ padding: "5px 0px" }}>
                        <Form.Check
                          type="radio"
                          label="Male"
                          name="gender"
                          value="Male"
                          onChange={handleChange}
                        />
                        <Form.Check
                          type="radio"
                          label="Female"
                          name="gender"
                          value="Female"
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Group>
                  </Row>
                  <Form.Group className="mb-3" controlId="formGridHealthIssues">
                    <Form.Label>Any Preferred Timings</Form.Label>
                    <div className='d-flex'>
                      <Form.Control
                        name="preferredTimings"
                        value={formData.preferredTimings}
                        onChange={handleChange}
                        type="time"
                      />
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formGridLifestyle">
                    <Form.Label>Any Message</Form.Label>
                    <Form.Control
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      type="text"
                      placeholder="Enter your message"
                    />
                  </Form.Group>
                  <div className='d-flex justify-content-between'>
                    <Button className='squBtn w-100' onClick={handleCreatePlan}>Create my plan</Button>
                    <Button className='position-absolute closeBtn' onClick={onHide}>
                      <i className="bi bi-x-circle-fill" style={{ fontSize: '32px' }}></i>
                    </Button>
                  </div>
                </Form>
              </div>
            )}
            {step === 3 && (
              <div className='d-flex justify-content-center align-items-center flex-column'>
                <h3 className='fw-bold' style={{ color:'#32794F' }}>Well done</h3>
                <p className='text-center fs-5 fw-normal'>Get started to a healthy lifestyle.</p>
                <p className='text-center fs-5 fw-normal'>Our Nutritionist will shortly contact you back.</p>
                <Button className='position-absolute closeBtn' onClick={onHide}>
                  <i className="bi bi-x-circle-fill" style={{ fontSize: '32px' }}></i>
                </Button>
              </div>
            )}
          </div>
        </div>
        <ToastContainer />
      </Modal.Body>
    </Modal>
  );
}
