import React from 'react'
import Intro from './components/intro'
import { Plans } from './components/pricingComponents/plans'
import { PlanFeatues } from './components/pricingComponents/planFeatues';
import { Products } from './components/pricingComponents/products';
import introbg from "../src/assets/images/AbstractDesign.png"


export const Pricing = () => {
    return (
        <React.Fragment>
            <Intro 
                bgImage={introbg}
                title="Our Plans"
                paragraph="We provide a range of flexible and affordable options to assist you in achieving optimal health and nutrition."
            />
            <Plans />
            <PlanFeatues />
            <Products />
        </React.Fragment>
    );
}